import { Autocomplete, styled, TextField } from "@mui/material";
import { useState } from "react";
import GroupsIcon from "@mui/icons-material/Groups";

interface DepartmentSearchProps {
  departments: string[];
  onChange(department: string): void;
}

export default function DepartmentSearch(props: DepartmentSearchProps) {
  const { departments, onChange } = props;
  const [value, setValue] = useState<string | null>(null);

  const DepartmentIcon = styled(GroupsIcon)({
    fontSize: 30,
    position: "relative",
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 10,
      }}
    >
      <DepartmentIcon />
      <Autocomplete
        size="small"
        sx={{
          width: 230,
          "& .MuiAutocomplete-inputRoot": {
            backgroundColor: "white",
          },
        }}
        options={departments}
        value={value}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{ shrink: false }}
            placeholder="Search departments"
          />
        )}
        onChange={(e, dep) => {
          const department = dep === null ? "" : dep;
          onChange(department);
          setValue(dep);
        }}
      />
    </div>
  );
}
