import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { OrgChart } from "d3-org-chart";
import { PersonCard } from "./PersonCard";
import { Typography } from "@mui/material";
import * as d3 from "d3";
import { TreeUser } from "../graph";

export interface IOrgChart {
  rootNode: TreeUser | null;
  data: TreeUser[];
  onNodeClick(): void;
  searchedUser: string;
}

export function OrganzationChart(props: IOrgChart) {
  const { data, searchedUser } = props;
  const [error, setError] = useState("");

  const d3Container = useRef(null);
  const chartRef = useRef<any>(null);

  const nodeHeight = 90;
  const nodeWidth = 265;

  useEffect(() => {
    if (searchedUser !== "") {
      try {
        if (chartRef) {
          chartRef.current.setExpanded(searchedUser);
          chartRef.current.setHighlighted(searchedUser);
          chartRef.current.setCentered(searchedUser).render();
        }
      } catch (error) {}
    } else {
      try {
        chartRef.current.clearHighlighting();
      } catch {}
    }
  }, [searchedUser]);

  useLayoutEffect(() => {
    setError("");
    try {
      if (data && d3Container.current) {
        let chart: any = chartRef.current;
        if (!chart) {
          chart = new OrgChart();
          chartRef.current = chart;
        }
        chart
          .container(d3Container.current)
          .data(data)
          .nodeWidth((d: any) => nodeWidth)
          .nodeHeight((d: any) => nodeHeight)
          .childrenMargin((d: any) => 50)
          .compactMarginPair((d: any) => 50)
          .neightbourMargin((a: any, b: any) => 25)
          .siblingsMargin((d: any) => 25)
          .buttonContent(({ node, state }: any) => {
            return `<div style="px;color:#716E7B;border-radius:5px;padding:4px;font-size:10px;margin:auto auto;background-color:white;border: 1px solid #E4E2E9"> <span style="font-size:9px">${
              node.children
                ? `<i class="fas fa-angle-up"></i>`
                : `<i class="fas fa-angle-down"></i>`
            }</span> ${node.data._directSubordinates}  </div>`;
          })
          .nodeUpdate((d: any, i: any, arr: any, state: any) => {
            d3.select(d3Container.current)
              .select(".node-rect")
              .attr("stroke", (d: any) => {
                return "none";
              })
              .attr("stroke-width", 0);
          })
          .nodeContent((d: any, i: any, arr: any, state: any) => {
            return ReactDOMServer.renderToString(
              <PersonCard data={d.data} height={nodeHeight} width={nodeWidth} />
            );
          });

        try {
          if (data.length < 20) {
            chart.expandAll();
          }
        } catch {}
        chart.render();
      }
    } catch (error) {
      setError("Error while creating chart. Please contact IT.");
    }
  }, [data]);

  return (
    <div>
      {error !== "" ? (
        <div>
          <Typography>{error}</Typography>
        </div>
      ) : (
        <div
          ref={d3Container}
          style={{
            width: "100vw",
          }}
        />
      )}
    </div>
  );
}
