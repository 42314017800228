import {
  Avatar,
  IconButton,
  Paper,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { TreeUser } from "../graph";
import EmailIcon from "@mui/icons-material/Email";

interface PersonCardProps {
  data: TreeUser;
  width: number;
  height: number;
}

const PaperContent = styled(Box)({
  display: "flex",
  flexDirection: "row",
});
const PersonInfoContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  left: 10,
  justifyContent: "center",
  gap: 5,
});
const NameTypography = styled(Typography)({
  fontSize: 15,
  wordBreak: "break-word",
  maxWidth: 170,
  marginTop: -25,
});
const PositionTypography = styled(Typography)({
  fontSize: 12,
  color: "#919190",
  wordBreak: "break-word",
});
const DepartmentTypography = styled(Typography)({
  fontSize: 12,
  color: "#919190",
  wordBreak: "break-word",
});
const ContentWrapper = styled("div")({
  position: "relative",
  display: "flex",
  flexDirection: "row",
});
const AvatarWrapper = styled("div")({
  position: "relative",
  display: "flex",
  flexDirection: "column",
});

export function PersonCard(props: PersonCardProps) {
  const {
    mail,
    name,
    office,
    imageUrl,
    positionName,
    department,
    _highlighted,
  } = props.data;
  const { height, width } = props;

  let flagCode = "";
  switch (office) {
    case "ETC":
      flagCode = "https://flagcdn.com/no.svg";
      break;
    case "Remote US":
    case "PDQC":
    case "PPC":
      flagCode = "https://flagcdn.com/us.svg";
      break;
    case "NTiSL CO":
      flagCode = "https://flagcdn.com/gb.svg";
      break;
    case "DE":
      flagCode = "https://flagcdn.com/de.svg"
      break;
  }

  return (
    <Paper
      sx={{
        paddingTop: 3,
        backgroundColor: "#f7f7f7",
        border: _highlighted ? "3px solid #2074d4" : "none",
        borderRadius: 5,
        width: width,
        height: height,
      }}
    >
      <PaperContent>
        <AvatarWrapper>
          <Avatar
            src={imageUrl}
            sx={{
              position: "relative",
              top: -30,
              left: -2,
            }}
          />
          {flagCode && (
            <img
              src={flagCode}
              width="25"
              alt="Office"
              style={{
                position: "absolute",
                top: 15,
                left: 5,
              }}
            />
          )}
        </AvatarWrapper>
        <ContentWrapper>
          <PersonInfoContent>
            <NameTypography>{name}</NameTypography>
            <PositionTypography>{positionName}</PositionTypography>
            <DepartmentTypography>{department}</DepartmentTypography>
          </PersonInfoContent>
        </ContentWrapper>
      </PaperContent>

      <Tooltip
        title="Send email"
        sx={{
          zIndex: 1000,
        }}
      >
        <IconButton
          href={`mailto:${mail}`}
          sx={{
            position: "absolute",
            height: 30,
            top: 1,
            right: 0,
          }}
        >
          <EmailIcon />
        </IconButton>
      </Tooltip>
    </Paper>
  );
}
