import { styled, Typography } from "@mui/material";

interface IErrorMessageProps {
  message: string;
  errorMessage: string;
}

const TextWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 10,
});
export function ErrorMessage(props: IErrorMessageProps) {
  const { message, errorMessage } = props;
  console.log(message, errorMessage);
  return (
    <TextWrapper>
      <Typography variant="h4">Error!</Typography>
      <Typography>{message}</Typography>
      <Typography
        sx={{
          fontSize: 15,
          color: "#919190",
        }}
      >
        {errorMessage}
      </Typography>
    </TextWrapper>
  );
}
