import React, { useEffect } from "react";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { AppBar, Box, Button, Grid, Toolbar, Typography } from "@mui/material";
import { loginRequest } from "./config/authConfig";
import { styled } from "@mui/system";
import OrganizationChartPage from "./components/OrganizationChartPage";

interface AppProps {
  msalInstance: PublicClientApplication;
}

const AppHeader = styled(AppBar)({
  height: 60,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
});

function App(props: AppProps) {
  const { msalInstance } = props;

  return (
    <MsalProvider instance={msalInstance}>
      <div>
        <Toolbar />
        <Grid
          container
          spacing={3}
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 5 }}
        >
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            spacing={3}
          >
            <UnauthenticatedTemplate>
              <AppHeader position="fixed">
                <Typography variant="h5">NTi Organizational Chart</Typography>
              </AppHeader>
            </UnauthenticatedTemplate>
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
            >
              <OrganizationChartPage />
            </MsalAuthenticationTemplate>
          </Grid>
        </Grid>
      </div>
    </MsalProvider>
  );
}

export default App;
