import {
  Autocomplete,
  Box,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { TreeUser } from "../graph";
import PersonIcon from "@mui/icons-material/Person";

interface EmployeeSearchProps {
  users: TreeUser[];
  onChange(id: string | null): void;
}
const EmployeeIcon = styled(PersonIcon)({
  fontSize: 30,
  position: "relative",
  top: 1,
});
export default function EmployeeSearch(props: EmployeeSearchProps) {
  const { users, onChange } = props;
  const [value, setValue] = useState<TreeUser | null>(null);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 10,
      }}
    >
      <EmployeeIcon />

      <Autocomplete
        size="small"
        sx={{
          width: 230,
          "& .MuiAutocomplete-inputRoot": {
            backgroundColor: "white",
          },
        }}
        options={users}
        value={value}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0, borderRadius: 5 } }}
            {...props}
          >
            <img loading="lazy" width="25" src={option.imageUrl} alt="" />
            <div style={{ display: "flex", flexDirection: "column" }}>
              {option.name}
              <Typography sx={{ fontSize: 12, color: "#b0afae" }}>
                {option.department}
              </Typography>
            </div>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{ shrink: false }}
            placeholder="Search employees"
          />
        )}
        onChange={(e, emp) => {
          const id = emp === null ? "" : emp.id;
          onChange(id);
          setValue(emp);
        }}
      />
    </div>
  );
}

//value={value}
