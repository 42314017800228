import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfig } from "./config/authConfig";
import { CssBaseline } from "@mui/material";
import { theme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";

export const msalInstance = new PublicClientApplication(msalConfig);
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App msalInstance={msalInstance} />
    </ThemeProvider>
  </React.StrictMode>,

  document.getElementById("root")
);
